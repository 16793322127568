import {
  ArrowLeftStartOnRectangleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRef, useState } from "react";
import { clearActiveSessionFromLocalStorage } from "~/lib/utils";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { useLogger } from "~/providers/LoggerProvider";
import ChevronCorrectIcon from "./ChevronCorrectIcon";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { Separator } from "../ui/separator";
import SocialConnections from "./SocialConnections";

export default function Profile() {
  const { teamAccountUser } = useTeamAccountUser();
  const [showModal, setShowModal] = useState(false);
  const logger = useLogger();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowModal(false));
  if (!teamAccountUser) return null;
  if (!teamAccountUser.image || !teamAccountUser.name) return null;
  const openModal = () => {
    setShowModal(!showModal);
  };
  const hasGithub =
    teamAccountUser &&
    teamAccountUser.accounts.some((account) => account.provider === "github");
  const hasGoogle =
    teamAccountUser &&
    teamAccountUser.accounts.some((account) => account.provider === "google");
  const handleSignout = async () => {
    try {
      clearActiveSessionFromLocalStorage();
      await signOut({ redirectTo: "/" });
    } catch (err) {
      logger.error(err, "Profile.tsx");
    }
  };

  return (
    <div ref={ref} className="relative z-10 flex gap-1">
      <button onClick={openModal}>
        <Image
          width={30}
          height={30}
          className="rounded-full"
          alt={teamAccountUser.name}
          src={teamAccountUser.image}
        />
      </button>
      <button onClick={openModal}>
        <ChevronCorrectIcon
          open={showModal}
          className="relative top-1 h-4 w-4 stroke-dark"
        />
      </button>
      {showModal && (
        <ul className="list-style-none absolute right-0 top-9 z-50 w-56 cursor-pointer space-y-4 rounded-md border border-vlight bg-offwhite p-6 font-medium shadow-lg">
          <li>
            <Link className="flex w-full justify-between" href="/settings">
              Settings
              <Cog6ToothIcon className="sm-icon" />
            </Link>
          </li>
          <li>
            <button
              className="flex w-full justify-between"
              onClick={handleSignout}
            >
              Sign out <ArrowLeftStartOnRectangleIcon className="sm-icon" />
            </button>
          </li>
          {(!hasGithub || !hasGoogle) && <Separator />}
          <SocialConnections hasGithub={hasGithub} hasGoogle={hasGoogle} />
        </ul>
      )}
    </div>
  );
}
