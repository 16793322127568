"use client";
import { useState } from "react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";
import Button from "~/components/base/Button";
import Input from "~/components/base/Input";
import HeroPage from "~/components/layout/HeroPage";
import Notification from "~/components/base/Notification/Notification";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import type { TeamAccountWithStripeSubscription } from "~/providers/TeamAccountProvider";
import useNotificationHashCreator from "../base/Notification/useNotificationHashCreator";
export default function CreateTeam({
  setTeamAccounts,
}: {
  setTeamAccounts: () => Promise<
    TeamAccountWithStripeSubscription | null | undefined
  >;
}) {
  const [teamName, setTeamName] = useState("");
  const { teamAccountUser, updateTeamAccountUser } = useTeamAccountUser();
  const [noTeamName, setNoTeamName] = useState(false);
  const { mutate, status } = api.teamAccount.create.useMutation({
    onSuccess: (data) => {
      if (!teamAccountUser || !data) return;
      const teamAccountIds = teamAccountUser.teamAccountIds ?? [];
      updateTeamAccountUser({
        ...teamAccountUser,
        teamAccountIds: [...teamAccountIds, data.id],
      });
      setTeamAccounts().catch((err) => {
        console.error(err, "error");
      });
    },
  });
  const createTeamAccount = () => {
    if (teamAccountUser == null) return;
    if (!teamName) {
      setNoTeamName(true);
      return;
    }

    mutate({ name: teamName });
  };

  const isAddingTeam = status === "pending";
  const isTeamCreated = status === "success";
  const noTeamNameHash = useNotificationHashCreator({
    today: new Date().toISOString(),
  });
  return (
    <HeroPage>
      <div className="flex h-screen w-1/2  flex-col content-center items-center justify-center">
        <div className="text mb-10 text-3xl font-semibold">
          Create your team
          {noTeamName && (
            <Notification
              id="CreateTeam"
              hash={noTeamNameHash}
              className="mt-4"
            >
              Please enter a team name.
            </Notification>
          )}
        </div>

        <Input
          value={teamName}
          setValue={setTeamName}
          className="mb-4 !w-2/3 px-6 py-4 text-2xl"
          placeholder="My team name"
          disabled={isAddingTeam || isTeamCreated}
        />
        <Button
          className="flex w-2/3 justify-center px-6 py-4 text-2xl font-bold"
          onClick={createTeamAccount}
          disabled={isAddingTeam || isTeamCreated}
        >
          Create Team
          <LoadingSpinner
            className="ml-2 text-gray-500"
            show={isAddingTeam || isTeamCreated}
          />
        </Button>
      </div>
    </HeroPage>
  );
}
