import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { RocketIcon, Cross1Icon, InfoCircledIcon } from "@radix-ui/react-icons";
import useNotificationHash from "./useNotificationHash";

export default function Notification({
  children,
  className = "",
  info = false,
  title = "",
  isLoading = false,
  hash = "",
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  info?: boolean;
  isLoading?: boolean;
  title?: string;
  id: string;
  version?: number;
  hash?: string;
}>) {
  const { isVisible, handleClose } = useNotificationHash(hash);

  if (!isVisible) return null;

  return (
    <div className={`relative pb-3 ${className}`}>
      <Alert>
        {!info ? (
          <RocketIcon className="h-4 w-4" />
        ) : (
          <InfoCircledIcon className="h-4 w-4" />
        )}
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription isLoading={isLoading}>
          <div className="pr-8 pb-1">{children}</div>
        </AlertDescription>
        <div>
          <button
            className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-100"
            onClick={handleClose}
          >
            <Cross1Icon className="h-4 w-4" />
          </button>
        </div>
      </Alert>
    </div>
  );
}
