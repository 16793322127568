import { cn } from "~/lib/utils";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "../base/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { getPriceString } from "./PricingCard";
import type { ConfirmChangesState } from "./hooks/useSubscriptionManagement";
import { formatDate } from "~/lib/utils";

export function UpdateActivePlanConfirmationModal({
  isOpen,
  onClose,
  confirmChangesData,
  handleSubscriptionUpdate,
  isUpdatingPlan,
  isAnnual,
}: {
  isOpen: boolean;
  onClose: () => void;
  confirmChangesData: ConfirmChangesState["state"];
  handleSubscriptionUpdate: () => void;
  isUpdatingPlan: boolean;
  isAnnual: boolean;
}) {
  if (!confirmChangesData) return null;

  const {
    currentPlanName,
    preparedUpdatedPlan,
    priceProrationAdjustment,
    customerPaymentDetails,
    isPlanDowngrade,
    currentSubscription,
  } = confirmChangesData;

  // Safely format the period end date (Stripe timestamps are in seconds)
  const periodEnd = currentSubscription?.current_period_end
    ? formatDate(currentSubscription.current_period_end)
    : null;
  return isPlanDowngrade ? (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[600px] max-w-full">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Confirm Downgrade
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          <p>
            You are about to <strong>downgrade</strong> your subscription from{" "}
            <strong>{currentPlanName}</strong> to{" "}
            <strong>{preparedUpdatedPlan.name}</strong>.
          </p>

          <p>
            Your current plan will remain active until the end of your billing
            period on{" "}
            <strong>{periodEnd ?? "the end of this billing cycle"}</strong>.
            After that, your new plan ({preparedUpdatedPlan.name}) will
            automatically start.
          </p>

          <p className="text-muted-foreground">
            Note: No immediate charges or credits will be applied for this
            downgrade. If you need to revert this change, you can do so before
            your billing period ends.
          </p>

          <div className="flex justify-end gap-3 pt-4">
            <Button onClick={onClose} disabled={isUpdatingPlan}>
              Cancel
            </Button>
            <Button
              onClick={handleSubscriptionUpdate}
              disabled={isUpdatingPlan}
              className={cn({
                "cursor-not-allowed": isUpdatingPlan,
              })}
            >
              Confirm Downgrade
              <LoadingSpinner
                className="ml-2 text-gray-500"
                show={isUpdatingPlan}
              />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[600px] max-w-full">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Confirm changes
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h3 className="text-xl font-semibold">
                {preparedUpdatedPlan.name} subscription
              </h3>
              <p className="text-sm text-muted-foreground">
                Billed {isAnnual ? "annually" : "monthly"}, starting today
              </p>
            </div>
            <div className="text-xl font-semibold">
              {getPriceString(
                preparedUpdatedPlan.price,
                preparedUpdatedPlan.currency
              )}
            </div>
          </div>

          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h3 className="text-xl font-semibold">Adjustment</h3>
              <p className="text-sm text-muted-foreground">
                Prorated credit for the remainder of your {currentPlanName}{" "}
                subscription
              </p>
            </div>
            <div className="text-xl font-semibold text-green-600">
              {getPriceString(
                priceProrationAdjustment.adjustment,
                priceProrationAdjustment.currency
              )}
            </div>
          </div>

          <div className="flex justify-between items-center border-t pt-6">
            <h3 className="text-xl font-semibold">Total due today</h3>
            <div className="text-xl font-semibold">
              {getPriceString(
                priceProrationAdjustment.dueAmount,
                priceProrationAdjustment.currency
              )}
            </div>
          </div>

          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">Payment Method</h3>
            <div className="text-muted-foreground">
              {customerPaymentDetails.brand?.toUpperCase()} *
              {customerPaymentDetails.last4}
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <Button onClick={onClose} disabled={isUpdatingPlan}>
              Cancel
            </Button>
            <Button
              onClick={handleSubscriptionUpdate}
              disabled={isUpdatingPlan}
              className={cn({
                "cursor-not-allowed": isUpdatingPlan,
              })}
            >
              Pay now
              <LoadingSpinner
                className="ml-2 text-gray-500"
                show={isUpdatingPlan}
              />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
