import { AlertTriangle } from "lucide-react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
} from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";

const DeleteContactsModal = ({
  isOpen,
  onOpenChange,
  newPriceTotalContacts,
  currentContactCount,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  newPriceTotalContacts: number;
  currentContactCount: number;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger>
        {/* No trigger needed, will be controlled programmatically */}
      </DialogTrigger>

      <DialogContent className="w-[500px]">
        <DialogHeader className="space-y-5">
          <DialogTitle className="flex items-center gap-2">
            <AlertTriangle className="h-5 w-5" />
            Action Required: Delete Contacts
          </DialogTitle>
          <div className="space-y-3">
            <DialogDescription className="text-base">
              To proceed with downgrading your plan, please delete contacts.
            </DialogDescription>
            <p className="text-sm font-medium text-destructive">
              You'll need to remove{" "}
              <span className="font-semibold">
                {Math.abs(newPriceTotalContacts - currentContactCount) + " "}
              </span>
              contact
              {Math.abs(newPriceTotalContacts - currentContactCount) !== 1
                ? "s"
                : ""}{" "}
              to meet the new plan limit.
            </p>
          </div>
        </DialogHeader>
        <DialogFooter className="mt-1">
          <Button className="w-full" onClick={() => onOpenChange(false)}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteContactsModal;
