import { useRouter, useSearchParams } from "next/navigation";

import { useEffect } from "react";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";
import type { TeamAccountWithStripeSubscription } from "~/providers/TeamAccountProvider";

export default function useInvite(
  setTeamAccounts: () => Promise<
    TeamAccountWithStripeSubscription | null | undefined
  >
) {
  const params = useSearchParams();

  const code = params.get("code");
  const router = useRouter();
  const logger = useLogger();
  const { mutate, status } = api.teamAccount.joinTeam.useMutation({
    onSuccess: () => {
      router.push("/settings");
      setTeamAccounts().catch((err) => {
        console.error(err, "error");
      });
    },
  });
  const { teamAccountUser } = useTeamAccountUser();
  useEffect(() => {
    if (
      !code ||
      !teamAccountUser ||
      status === "pending" ||
      status === "success"
    ) {
      return;
    }

    mutate({ code: code as string });
  }, [code, mutate, teamAccountUser, logger]);
}
