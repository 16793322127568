import { Check } from "lucide-react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";
import { Badge } from "../ui/badge";

export function getPriceString(price: number, currency: string) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(price / 100);
}

interface PricingCardProps {
  name: string;
  description: string;
  price: number;
  currency?: string;
  billingInterval?: string;
  isCurrentPlan: boolean;
  choicedPlanPriceId: string | null;
  onChoosePlan: () => void;
  isAnnual: boolean;
  actionButtonText: string;
  isMostPopular?: boolean;
  features?: { name: string }[];
  isSwitchingPlanInterval: boolean;
  isActive?: boolean;
}

export default function PricingCard({
  name,
  description,
  price,
  currency,
  billingInterval,
  isCurrentPlan,
  isActive,
  isMostPopular,
  onChoosePlan,
  actionButtonText,
  isSwitchingPlanInterval,
  features,
}: PricingCardProps) {
  const priceString =
    price === -1 ? "Custom" : currency ? getPriceString(price, currency) : "";

  return (
    <div
      className={cn(
        "relative rounded-2xl border p-8 shadow-sm transition-shadow hover:shadow-md",
        isMostPopular && "border-primary bg-primary/10"
      )}
    >
      {isMostPopular && (
        <Badge
          className="absolute -top-2.5 left-1/2 -translate-x-1/2"
          variant="secondary"
        >
          Most Popular
        </Badge>
      )}
      <div className="mb-4">
        <h3 className="text-2xl font-bold">{name}</h3>
        <p className="text-muted-foreground">{description}</p>
      </div>
      <div className="mb-4">
        <span className="text-4xl font-bold">{priceString}</span>
        <span className="text-muted-foreground">
          {billingInterval
            ? billingInterval === "month"
              ? "/month"
              : "/year"
            : ""}
        </span>
      </div>
      <ul className="mb-8 space-y-3">
        {features?.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check className="h-5 w-5 text-primary" />
            <span>{feature?.name}</span>
          </li>
        ))}
      </ul>
      <Button
        className="w-full"
        variant={isMostPopular ? "default" : "outline"}
        onClick={onChoosePlan}
        disabled={isCurrentPlan && !isSwitchingPlanInterval && isActive}
      >
        {isCurrentPlan && isActive
          ? isSwitchingPlanInterval
            ? "Modify Billing Interval"
            : "Current Plan"
          : actionButtonText}
      </Button>
    </div>
  );
}
