import { signIn } from "next-auth/react";
import { LinkIcon } from "lucide-react";

interface SocialConnectionsProps {
  hasGithub: boolean;
  hasGoogle: boolean;
}

export default function SocialConnections({
  hasGithub,
  hasGoogle,
}: SocialConnectionsProps) {
  return (
    <>
      {!hasGithub && (
        <li>
          <button
            className="flex w-full justify-between"
            onClick={() => signIn("github")}
          >
            Connect Github
            <LinkIcon className="sm-icon" />
          </button>
        </li>
      )}
      {!hasGoogle && (
        <li>
          <button
            className="flex w-full justify-between"
            onClick={() => signIn("google")}
          >
            Connect Google
            <LinkIcon className="sm-icon" />
          </button>
        </li>
      )}
    </>
  );
}
