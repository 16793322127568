import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import NavBar from "../layout/NavBar";
import SubscriptionPlans from "../settings/SubscriptionPlans";

export default function FreeTrialExpired() {
  return (
    <div>
      <NavBar />
      <div className="flex flex-col items-center content-center h-screen justify-center">
        <div className="flex flex-col w-2/3 pb-60  items-stretch content-center h-screen justify-center">
          <div className="flex  flex-col content-center items-center justify-center">
            <Alert
              variant="default"
              className="mb-8 mx-auto max-w-4xl p-4 pb-5"
            >
              <AlertCircle className="mt-1 h-5 w-5" />
              <AlertTitle className="text-xl">
                Your 30-day free trial has expired
              </AlertTitle>

              <AlertDescription className="text-lg">
                Please choose a plan below to continue using our service or{" "}
                <a
                  href="mailto:info@openq.dev"
                  className="font-medium underline underline-offset-4"
                >
                  contact our support team
                </a>
                .
              </AlertDescription>
            </Alert>
          </div>

          <SubscriptionPlans />
        </div>
      </div>
    </div>
  );
}
